import { createContext } from 'react';

import { GenericNotification } from '~/typings/notifications';

export interface NotificationContextInterface {
  notifications: GenericNotification[];
  addNotifications: (errors: GenericNotification[]) => void;
  clearNotifications: () => void;
  removeNotifications: (ids: string[]) => void;
}

export const NotificationContext = createContext<NotificationContextInterface>({
  notifications: [],
  addNotifications: () => null,
  clearNotifications: () => null,
  removeNotifications: () => null,
});

import { Alert, AlertTitle, styled, Typography } from '@mui/material';

import { timeAgo } from '~/libs/dates';
import { GenericNotification } from '~/typings/notifications';

import { NotificationContextInterface } from './context';

interface NotificationItemProps {
  notification: GenericNotification;
  removeNotifications: NotificationContextInterface['removeNotifications'];
}

const Notification = styled(Alert)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  width: 100%;
`;

const Clamp = styled('span')`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  /* Copied from distance between title and message, thx mui */
  margin-bottom: 0.35em;
`;

// In most cases, this won't come into play unless our lineclamps aren't supported
export const NOTIFICATION_MAX_LENGTH = 150;

export function NotificationItem({
  notification,
  removeNotifications,
}: NotificationItemProps) {
  const { id, message, title, createdOn, type } = notification;

  const removeNotification = () => {
    removeNotifications([id]);
  };

  return (
    <Notification
      severity={type}
      variant="outlined"
      onClose={removeNotification}
    >
      <AlertTitle>{title}</AlertTitle>
      {message && (
        <Clamp>
          {message.slice(0, NOTIFICATION_MAX_LENGTH)}
          {message.length > NOTIFICATION_MAX_LENGTH ? '...' : ''}
        </Clamp>
      )}
      <Typography
        sx={{
          color: 'text.secondary',
        }}
        variant="caption"
      >
        {timeAgo(createdOn)}
      </Typography>
    </Notification>
  );
}

import { createTheme, ThemeOptions } from '@mui/material/styles';
import { merge } from 'lodash';
import localFont from 'next/font/local';

import { DeepPartial } from '~/typings/util';

export const mapIconTextBgColor = '#f0f8ff';

/**
 * per https://mui.com/customization/breakpoints/
 * xs, extra-small: 0px
 * sm, small: 600px
 * md, medium: 900px
 * lg, large: 1200px
 * xl, extra-large: 1536px
 */

export const sharedTheme: DeepPartial<ThemeOptions> = {
  spacing: (factor: number) => `${0.5 * factor}rem`,
};

export const darkTheme: DeepPartial<ThemeOptions> = {
  palette: {
    mode: 'dark',
  },
};

export const lightTheme: DeepPartial<ThemeOptions> = {
  palette: {
    mode: 'light',
  },
};

// // Typing of createTheme seems to expect a fully formed ThemeOptions obj
// // but the documentation suggests that this can be an obj that overrides
// // any subset of fields, hence the re-typing here.
// // see https://mui.com/customization/palette/#adding-new-colors
// export const createThemeByMode = (mode: PaletteMode) =>
//   createTheme(
//     merge(sharedTheme, mode === 'dark' ? darkTheme : lightTheme) as Record<
//       string,
//       unknown
//     >
//   );

// eslint-disable-next-line camelcase
export const TEMP_DarkTheme = createTheme(
  merge(sharedTheme, darkTheme) as Record<string, unknown>
);

// FONTS
export const beholdenFont = localFont({
  src: [
    {
      path: '../../public/fonts/Beholden/beholden-regular.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../../public/fonts/Beholden/beholden-medium.woff2',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../../public/fonts/Beholden/beholden-bold.woff2',
      weight: '700',
      style: 'normal',
    },
    {
      path: '../../public/fonts/Beholden/beholden-italic.woff2',
      weight: '400',
      style: 'italic',
    },
    {
      path: '../../public/fonts/Beholden/beholden-medium-italic.woff2',
      weight: '500',
      style: 'italic',
    },
    {
      path: '../../public/fonts/Beholden/beholden-bold-italic.woff2',
      weight: '700',
      style: 'italic',
    },
  ],
});

/* eslint-disable camelcase */
import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AppProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { TEMP_DarkTheme } from '~/constants/theme';
import { NotificationContextProvider } from '~/libs/context/NotificationContext';

const queryClient = new QueryClient();

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <SessionProvider session={session}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={TEMP_DarkTheme}>
            <CssBaseline />
            <NotificationContextProvider>
              <main>
                <Component {...pageProps} />
              </main>
            </NotificationContextProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </SessionProvider>
    </LocalizationProvider>
  );
}

export default MyApp;

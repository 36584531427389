import { Box, styled } from '@mui/material';
import { useMemo, useState } from 'react';

import { pxToRem } from '~/libs/style';
import { GenericNotification } from '~/typings/notifications';

import { NotificationContext } from './context';
import { NotificationItem } from './NotificationItem';

const emptyNotifications: GenericNotification[] = [];

const NotificationsList = styled(Box)`
  position: fixed;
  right: 0;
  top: 0;
  overflow: auto;
  max-height: 100vh;
  z-index: 8888;
`;

interface NotificationContextProviderProps {
  children: React.ReactNode;
}

export function NotificationContextProvider({
  children,
}: NotificationContextProviderProps) {
  const [notifications, setNotifications] =
    useState<GenericNotification[]>(emptyNotifications);

  const addNotifications = (newErrors: GenericNotification[]) => {
    setNotifications((prevErrors) => [...prevErrors, ...newErrors]);
  };

  const clearNotifications = () => {
    setNotifications(emptyNotifications);
  };

  const removeNotifications = (ids: string[]) => {
    setNotifications((prevErrors) =>
      prevErrors.filter((error) => !ids.includes(error.id))
    );
  };

  const providerValue = useMemo(
    () => ({
      notifications,
      addNotifications,
      clearNotifications,
      removeNotifications,
    }),
    [notifications]
  );

  return (
    <NotificationContext.Provider value={providerValue}>
      {children}
      {Boolean(notifications.length) && (
        <NotificationsList
          display="flex"
          flexDirection="column"
          gap={1}
          maxWidth="100vw"
          p={2}
          pb={0}
          width={pxToRem(360)}
        >
          {notifications.map((notification) => (
            <NotificationItem
              key={notification.id}
              notification={notification}
              removeNotifications={removeNotifications}
            />
          ))}
        </NotificationsList>
      )}
    </NotificationContext.Provider>
  );
}
